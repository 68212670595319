import React, { useState } from 'react';
import axios from 'axios';

function App() {
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');

  // 서버로 요청을 보내는 함수
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // 주어진 URL로 POST 요청
      const result = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo', // 모델 이름 추가
          messages: [
            { role: 'user', content: prompt }
          ],
        },
        {
          headers: {
            'Authorization': `Bearer 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`,
            'Content-Type': 'application/json',
          },
        }
      );

      // 요청에 대한 응답 저장
      const gptResponse = result.data.choices[0].message.content; // 응답 형식에 맞춰 수정
      setResponse(gptResponse);
    } catch (error) {
      console.error('Error fetching response:', error);
      setResponse('Something went wrong, please try again.');
    }
  };

  return (
    <div className="App">
      <h1>Chat with Custom GPT API</h1>
      <form onSubmit={handleSubmit}>
        <textarea
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Ask me anything..."
          rows="5"
          style={{ width: '100%' }}
        />
        <button type="submit">Send</button>
      </form>
      {response && (
        <div className="response">
          <h2>Response:</h2>
          <p>{response}</p>
        </div>
      )}
    </div>
  );
}

export default App;
